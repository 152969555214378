<script setup lang="ts">
import type { Promotion } from "@/types";

const { t } = useT();
const isGuest = useIsGuest();
const { open } = useNlcModals();
const { data } = useAppInitData();

defineProps<{
	title: string;
	subTitle?: string;
	type?: Promotion["type"];
	image: string;
	entries: number;
	promotion: Promotion;
}>();

const handleClick = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: "lets_go",
		type: data.value?.gameMode === "SweepStakes" ? "entries" : "coins",
		location: "promo_page"
	});
	open("LazyOModalSpookySeasonTower");
};
</script>

<template>
	<MPromotionWrapper :type="type" :image="image" :buttonName="promotion.data?.buttonText" @click-card="handleClick">
		<template #top>
			<AText :size="18" class="title" :modifiers="['medium', 'center', 'uppercase']" as="div">{{ title }}</AText>
			<MPromotionCoins v-if="!isGuest" :entries="+t('season.prize-pool')" />
		</template>

		<MCounterBadgeGroup
			v-if="promotion.data?.finishedAt"
			:timestamp="promotion.data?.finishedAt ?? ''"
			:badgeText="t('Limited Offer')"
		/>
	</MPromotionWrapper>
</template>

<style lang="scss" scoped>
.card-season > :deep(.top) {
	padding: 24px;
}
</style>
